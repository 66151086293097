@import "../../_globalColor";

span.link-tag {
    background: $buttonColor;
    color: $lightBackground3;
    vertical-align: middle;
    align-items: center;
    border-radius: 4px;
    display: inline-flex;
    font-size: 0.75rem;
    height: 2em;
    justify-content: center;
    white-space: nowrap;
    line-height: 1.5;
    margin: 0 0.5rem 0.5rem 0;
    padding: 0 0.75em;
    cursor: pointer;
    transition: 0.2s ease-in;
  }

  span.link-tag:hover {
    background: $buttonHover;
  }